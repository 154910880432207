// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const QUERY = gql`
  query DossierAuditDetailPage(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID!,
    $context: String
  ) {
    form: dossierAuditDetailPage(
      dossierId: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId,
      context: $context
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { QUERY };
